export default {
  getEstablishmentTextForPrison(establishment, capitalize) {
    let text = 'prison';

    if (establishment === 'hospital') {
      text = 'hospital';
    }

    return capitalize ? this.capitalize(text) : text;
  },
  getEstablishmentTextForPrisoner(establishment, capitalize) {
    let text = 'prisoner';

    if (establishment === 'hospital') {
      text = 'patient';
    }

    return capitalize ? this.capitalize(text) : text;
  },
  capitalize(text) {
    if (!text) return '';

    return text.charAt(0).toUpperCase() + text.slice(1);
  },
};
