import NetworkService from '@/services/NetworkService';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    network: null,
  },
  getters: {},
  mutations: {
    SET_NETWORK(state, value) {
      state.network = value;
    },
  },
  actions: {
    async getNetwork({ commit }, uid) {
      return NetworkService.getNetworks(uid)
        .then(res => {
          // todo replace this with get when ready
          const network = res.data.find(item => item.uid === uid);
          commit('SET_NETWORK', network);
        })
        .catch(() => {
          Vue.$toast.error('Could not get network, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        })
    },
    deleteNetwork() {},
  },
};
