export default [
  {
    path: '/admin/eapi-policies',
    name: 'eapi-policies',
    component: () => import('@/views/policies/EapiPolicies.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'EAPI Policies',
      breadcrumb: [
        {
          text: 'EAPI Policies',
          active: true,
        },
      ],
    },
  },
]
