import axios from '@axios';

const baseUrl = '/roles';

export default {
  getRoles() {
    return axios.get(`${baseUrl}`);
  },
  getRole(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  getPermissions() {
    return axios.get('/permissions');
  },
  addRole(name) {
    return axios.post(`${baseUrl}`, { name });
  },
  updateRole(id, updateDTO) {
    return axios.put(`${baseUrl}/${id}`, updateDTO);
  },
  deleteRole(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
};
