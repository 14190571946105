export default [
  {
    path: '/admin/kiosk-sites',
    name: 'kiosk-sites',
    component: () => import('@/views/kiosk/KioskSite.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Sites',
      breadcrumb: [
        {
          text: 'All Kiosk Sites',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-sites/kiosk-site/:id',
    name: 'kiosk-site-view',
    component: () => import('@/views/kiosk/viewKioskSites/ViewKioskSite.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Site View',
      breadcrumb: [
        {
          text: 'All Kiosk Sites',
          to: { name: 'kiosk-sites' },
        },
        {
          text: 'View Kiosk Site',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-blacklist',
    name: 'kiosk-blacklist',
    component: () => import('@/views/kiosk/KioskBlacklist.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Blacklist',
      breadcrumb: [
        {
          text: 'Kiosk Blacklist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-tag',
    name: 'kiosk-tags',
    component: () => import('@/views/kiosk/KioskTags.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Tags',
      breadcrumb: [
        {
          text: 'Kiosk Tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-channels',
    name: 'kiosk-channels',
    component: () => import('@/views/kiosk/KioskChannels.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Purple Play Channels',
      breadcrumb: [
        {
          text: 'Channels',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-global-css',
    name: 'kiosk-global-css',
    component: () => import('@/views/kiosk/KioskGlobalCss.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk Global CSS',
      breadcrumb: [
        {
          text: 'Global CSS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/kiosk-global-css',
    name: 'kiosk-news-feeds',
    component: () => import('@/views/kiosk/KioskNewsFeeds.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Kiosk News Feeds',
      breadcrumb: [
        {
          text: 'News Feeds',
          active: true,
        },
      ],
    },
  },
]
