export default [
  {
    path: '/locations',
    name: 'locations',
    props: true,
    component: () => import('@/views/locations/Locations.vue'),
    meta: {
      resource: 'Locations',
      action: 'Read',
      pageTitle: 'Locations',
      breadcrumb: [
        {
          text: 'All Locations',
          to: { name: 'locations' },
        },
      ],
    },
  },
  {
    path: '/locations/:id',
    name: 'location-details',
    props: true,
    component: () => import('@/views/locations/LocationDetails.vue'),
    meta: {
      resource: 'Locations',
      action: 'Read',
      pageTitle: 'Location Details',
      breadcrumb: [
        {
          text: 'All Locations',
          to: { name: 'locations' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
]
