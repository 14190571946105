import auth from '@/auth/auth'
import store from '../store'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  if (auth.initialised === false) {
    await auth.refreshCurrentUser()
  }

  if (store.state.user.currentUser === null) {
    return false
  }
  return store.state.user.currentUser && store.state.user.accessToken
}

/**
 * All roles will just got to the root by default
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  return '/'
}
