import axios from '@axios';

const baseUrl = '/networks';

export default {
  getNetworks() {
    return axios.get(`${baseUrl}`);
  },
  getNetworksSlim() {
    return axios.get(`${baseUrl}/slim`);
  },
  createNetwork(network) {
    return axios.post(`${baseUrl}`, network);
  },
  getNetwork(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  deleteNetwork(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  updateNetwork(uid, networkDto) {
    return axios.put(`${baseUrl}/${uid}`, networkDto);
  },
};
