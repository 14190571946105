export default [
  {
    path: '/purple-scribe',
    name: 'purple-scribe',
    component: () => import('@/views/purpleScribe/PurpleScribeJobs.vue'),
    meta: {
      resource: 'Scribe',
      action: 'Read',
      pageTitle: 'Purple Scribe Sessions',
      breadcrumb: [
        {
          text: 'Purple Scribe',
          active: true,
        },
      ],
    }
  },
  {
    path: '/purple-scribe/dashboard',
    name: 'purple-scribe-dashboard',
    component: () => import('@/views/purpleScribe/PurpleScribeDashboard.vue'),
    meta: {
      resource: 'Scribe',
      action: 'Read',
      pageTitle: 'Purple Scribe Dashboard',
      breadcrumb: [
        {
          text: 'Purple Scribe Dashboard',
          active: true,
        },
      ],
    }
  },
  {
    path: '/purple-scribe/:id',
    name: 'purple-scribe-details',
    component: () => import('@/views/purpleScribe/sections/PurpleScribeJobDetails.vue'),
    meta: {
      resource: 'Scribe',
      action: 'Read',
      pageTitle: 'Purple Scribe Session Details',
      breadcrumb: [
        {
          text: 'Purple Scribe',
          to: {name: 'purple-scribe'}
        },
        {
          text: 'Session Details',
          active: true,
        }
      ],
    }
  }
]
