export default [
  {
    path: '/issues',
    name: 'issues',
    component: () => import('@/views/issues/Issues.vue'),
    meta: {
      resource: 'Issue',
      action: 'Create',
      pageTitle: 'Issue',
      breadcrumb: [
        {
          text: 'Submit an issue',
          active: true,
        },
      ],
    },
  },
  {
    path: '/issues/submitted/:uid',
    name: 'issue-submitted',
    props: true,
    component: () => import('@/views/issues/IssuesSubmitted.vue'),
    meta: {
      resource: 'Issue',
      action: 'Create',
      pageTitle: 'Issue Submitted',
      breadcrumb: [
        {
          text: 'Issues',
          to: { name: 'issues' },
        },
        {
          text: 'Issue submitted',
        },
      ],
    },
  },
]
