import EnterpriseService from '@/services/EnterpriseService';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    enterprise: null,
    apps: [],
  },
  getters: {},
  mutations: {
    SET_ENTERPRISE(state, value) {
      state.enterprise = value;
    },
    SET_APPS(state, value) {
      state.apps = value;
    },
  },
  actions: {
    getEnterprise({ commit }, uid) {
      return EnterpriseService.getEnterprise(uid).then(res => {
        commit('SET_ENTERPRISE', res.data);
      }).catch(() => {
        Vue.$toast.error('Could not get enterprise, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    getApps({ commit }, uid) {
      return EnterpriseService.getAllApplications(uid).then(res => {
        commit('SET_APPS', res.data);
      }).catch(() => {
        Vue.$toast.error('Could not get enterprise applications, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
  },
};
