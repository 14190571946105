export default [
  {
    path: '/purple-play',
    name: 'purple-play',
    component: () => import('@/views/purplePlay/PurplePlayChannels.vue'),
    meta: {
      resource: 'PurplePlay',
      action: 'Read',
      pageTitle: 'Purple Play - Channels',
      breadcrumb: [
        {
          text: 'Purple Play',
          active: true,
        },
      ],
    }
  },
  {
    path: '/purple-play/channels/:id',
    name: 'purple-play-channel-details',
    component: () => import('@/views/purplePlay/sections/PurplePlayChannelDetails.vue'),
    meta: {
      resource: 'PurplePlay',
      action: 'Read',
      pageTitle: 'Purple Play - Channel Details',
      breadcrumb: [
        {
          text: 'Purple Play',
          to: {name: 'purple-play'}
        },
        {
          text: 'Channel Details',
          active: true,
        }
      ],
    }
  }
]
