export default [
  {
    path: '/admin/purple-docs',
    name: 'purple-docs-template-categories',
    component: () => import('@/views/purpleDocs/admin/PurpleDocsCategories.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Purple Docs',
      breadcrumb: [
        {
          text: 'All Template Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/purple-docs/templates/',
    name: 'purple-docs-templates',
    component: () => import('@/views/purpleDocs/admin/PurpleDocsTemplates.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Purple Docs',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents/',
    name: 'purple-docs-documents',
    component: () => import('@/views/purpleDocs/PurpleDocsDocuments.vue'),
    meta: {
      resource: 'PurpleDocs',
      action: 'Read',
      pageTitle: 'Purple Docs',
      breadcrumb: [
        {
          text: 'All Documents',
          active: true,
        },
      ],
    },
  },
  {
    path: '/documents/:id',
    name: 'purple-docs-document',
    component: () => import('@/views/purpleDocs/ViewDocument.vue'),
    meta: {
      resource: 'PurpleDocs',
      action: 'Read',
      pageTitle: 'Purple Docs',
      breadcrumb: [
        {
          text: 'All Documents',
          to: { name: 'purple-docs-documents' },
        },
        {
          text: 'Document View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/purple-docs/templates/:id',
    name: 'purple-docs-template',
    component: () => import('@/views/purpleDocs/admin/ViewTemplate.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Purple Docs',
      breadcrumb: [
        {
          text: 'All Templates',
          to: { name: 'purple-docs-templates' },
        },
        {
          text: 'Template Details',
          active: true,
        },
      ],
    },
  },
]
