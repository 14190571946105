export default [
  {
    path: '/enrollment',
    name: 'enrollment',
    props: true,
    component: () => import('@/views/enrollment/EnrollmentGroup.vue'),
    meta: {
      resource: 'Group',
      action: 'Read',
      pageTitle: 'Enrollment Group',
      breadcrumb: [
        {
          text: 'Enrollment Group',
          to: { name: 'enrollment' },
        },
      ],
    },
  },
  {
    path: '/enrollment/edit',
    name: 'enrollment-edit',
    props: true,
    component: () => import('@/views/enrollment/EditEnrollmentGroup.vue'),
    meta: {
      resource: 'Group',
      action: 'Update',
      pageTitle: 'Enrollment Group',
      breadcrumb: [
        {
          text: 'Enrollment Group',
          to: { name: 'enrollment' },
        },
        {
          text: 'Edit Enrollment Group',
          active: true,
        },
      ],
    },
  },
]
