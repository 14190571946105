export default [
  {
    path: '/tracker/:taskId?',
    name: 'tracker',
    component: () => import('@/views/tracker/ViewTasks.vue'),
    meta: {
      resource: 'Tracker',
      action: 'Read',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
]
