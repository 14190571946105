export default [
  {
    path: '/possessions/categories',
    name: 'possession-categories',
    props: true,
    component: () => import('@/views/possessions/Categories.vue'),
    meta: {
      resource: 'PossessionCategories',
      action: 'Read',
      pageTitle: 'Possession Categories',
      breadcrumb: [
        {
          text: 'All Categories',
          to: { name: 'possession-categories' },
        },
      ],
    },
  },
  {
    path: '/possessions/categories/:id',
    name: 'possessions-category',
    props: true,
    component: () => import('@/views/possessions/ViewCategory.vue'),
    meta: {
      resource: 'PossessionCategories',
      action: 'Read',
      pageTitle: 'Possession Category',
      breadcrumb: [
        {
          text: 'All Categories',
          to: { name: 'possession-categories' },
        },
        {
          text: 'Category View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/possessions',
    name: 'possessions',
    props: true,
    component: () => import('@/views/possessions/ViewPossessions.vue'),
    meta: {
      resource: 'Possessions',
      action: 'Read',
      pageTitle: 'Possessions',
      breadcrumb: [
        {
          text: 'All Possessions',
          to: { name: 'possession-categories' },
        },
      ],
    },
  },
  {
    path: '/possessions/:id',
    name: 'possession-details',
    props: true,
    component: () => import('@/views/possessions/PossessionDetails.vue'),
    meta: {
      resource: 'Possessions',
      action: 'Read',
      pageTitle: 'Possession Details',
      breadcrumb: [
        {
          text: 'All Possessions',
          to: { name: 'possessions' },
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
]
