export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/devices/Devices.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Devices',
      breadcrumb: [
        {
          text: 'Showing tablets and phones under your control',
          active: true,
        },
      ],
    },
  },
  {
    path: '/devices/device/:uid',
    name: 'device-view',
    props: true,
    component: () => import('@/views/devices/viewDevice/ViewDevice.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device',
      breadcrumb: [
        {
          text: 'All Devices',
          to: { name: 'devices' },
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
]
