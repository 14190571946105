import RolesService from '@/services/RolesService'
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    role: null,
  },
  getters: {},
  mutations: {
    SET_ROLE(state, value) {
      state.role = value;
    },
  },
  actions: {
    async getRole({ commit }, id) {
      return RolesService.getRole(id).then(res => {
        commit('SET_ROLE', res.data);
      }).catch(() => {
        Vue.$toast.error('Could not get roles, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
