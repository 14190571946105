export default [
  {
    path: '/two-factor/authorise',
    name: 'two-factor-authorise',
    component: () => import('@/views/two-factor/Authorise.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
    },
  },
  {
    path: '/two-factor/setup',
    name: 'two-factor-setup',
    component: () => import('@/views/two-factor/Setup.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
    },
  },
]
