export default [
  {
    path: '/admin/enterprises',
    name: 'enterprises',
    component: () => import('@/views/enterprises/Enterprises.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Enterprises',
      breadcrumb: [
        {
          text: 'All Enterprises',
          active: true,
          to: { name: 'enterprises' },
        },
      ],
    },
  },
  {
    path: '/admin/enterprises/create',
    name: 'enterprise-create',
    component: () => import('@/views/enterprises/Create.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      layout: 'full',
      pageTitle: 'Enterprises',
      breadcrumb: [
        {
          text: 'All Enterprises',
          to: { name: 'enterprises' },
        },
        {
          text: 'Create Enterprise',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/enterprises/enterprise/:uid',
    name: 'enterprise-view',
    props: true,
    component: () => import('@/views/enterprises/viewEnterprises/ViewEnterprise.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Enterprise',
      breadcrumb: [
        {
          text: 'All Enterprises',
          to: { name: 'enterprises' },
        },
        {
          text: 'View Enterprise',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/enterprises/edit-enterprise/:uid',
    name: 'enterprise-edit',
    props: true,
    component: () => import('@/views/enterprises/viewEnterprises/sections/UpdateEnterprise.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Enterprise',
      breadcrumb: [
        {
          text: 'All Enterprises',
          to: { name: 'enterprises' },
        },
        {
          text: 'Edit Enterprise',
          active: true,
        },
      ],
    },
  },
]
