export default [
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/groups/Groups.vue'),
    meta: {
      resource: 'Group',
      action: 'Read',
      pageTitle: 'Groups List',
      breadcrumb: [
        {
          text: 'All Groups',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/group/:uid',
    name: 'group-view',
    props: true,
    component: () => import('@/views/groups/viewGroup/ViewGroup.vue'),
    meta: {
      resource: 'Group',
      action: 'Read',
      pageTitle: 'Group',
      breadcrumb: [
        {
          text: 'All Groups',
          to: { name: 'groups' },
        },
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/group/:uid/edit',
    name: 'group-edit',
    props: true,
    component: () => import('@/views/groups/editGroup/EditGroup.vue'),
    meta: {
      resource: 'Group',
      action: 'Update',
      pageTitle: 'Group',
      breadcrumb: [
        {
          text: 'All Groups',
          to: { name: 'groups' },
        },
        {
          text: 'Edit Group',
          active: true,
        },
      ],
    },
  },
]
