import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/admin/enterprises';

export default {
  getEnterprises(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createEnterprise(enterprise) {
    return axios.post(`${baseUrl}`, enterprise);
  },
  // getSignupUrl(callBackUrl, projectId) {
  //   return axios.post(`${baseUrl}/url`, {
  //     callback_url: callBackUrl,
  //     project_id: projectId,
  //   });
  // },
  getEnterprise(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  updateEnterprise(uid, dto) {
    return axios.put(`${baseUrl}/${uid}`, dto);
  },
  getEnterpriseWebToken(uid) {
    return axios.get(`${baseUrl}/${uid}/token`);
  },
  getAllApplications(uid) {
    return axios.get(`${baseUrl}/${uid}/applications`);
  },
  createApplication(uid, application) {
    return axios.post(`${baseUrl}/${uid}/applications`, application);
  },
  updateApplication(uid, appId, application) {
    return axios.put(`${baseUrl}/${uid}/applications/${appId}`, application);
  },
  deleteApplication(uid, appId) {
    return axios.delete(`${baseUrl}/${uid}/applications/${appId}`);
  },
  getApplication(uid, appId) {
    return axios.get(`${baseUrl}/${uid}/applications/${appId}`);
  },
  getEnterpriseProxy(uid) {
    return axios.get(`${baseUrl}/${uid}/proxy`);
  },
  updateEnterpriseProxy(uid, enterpriseProxy) {
    return axios.put(`${baseUrl}/${uid}/proxy`, enterpriseProxy);
  },
  getProxyExcludeLists(uid) {
    return axios.get(`${baseUrl}/${uid}/proxy-exclude`);
  },
  createProxyExcludeList(uid, proxyExcludeListCreate) {
    return axios.post(`${baseUrl}/${uid}/proxy-exclude`, proxyExcludeListCreate);
  },
  deleteList(enterpriseUid, listUid) {
    return axios.delete(`${baseUrl}/${enterpriseUid}/proxy-exclude/${listUid}`);
  },
  updateList(uid, listId, list) {
    return axios.put(`${baseUrl}/${uid}/proxy-exclude/${listId}`, list);
  },
};
