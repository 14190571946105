import axios from '@axios';

class CountryService {
  // Axios Instance
  axiosIns = null

  // Default Country should be set to UK
  country = 'uk'

  // Allowed Countries
  allowedCountries = ['uk', 'aus', 'local']

  constructor(axiosIns) {
    this.axiosIns = axiosIns
    this.setCountryFromLocalStorage()
  }

  getApiUrl() {
    return this.axiosIns.defaults.baseURL
  }

  // Set the API and country if it's currently set in the local storage, otherwise set to the default of UK
  setCountryFromLocalStorage() {
    const countryFromStorage = localStorage.getItem('country')
    if (this.allowedCountries.includes(countryFromStorage)) {
      this.country = countryFromStorage
    }
    this.setApiBasedOnCountry(countryFromStorage)
  }

  // Update the country the user is using, switches the
  updateCountry(country) {
    localStorage.setItem('country', country)
    this.setApiBasedOnCountry(country)
  }

  setApiBasedOnCountry(country) {
    if (country === 'uk') {
      this.axiosIns.defaults.baseURL = process.env.VUE_APP_API_UK
    } else if (country === 'aus') {
      this.axiosIns.defaults.baseURL = process.env.VUE_APP_API_AUS
    } else if (country === 'local') {
      this.axiosIns.defaults.baseURL = process.env.VUE_APP_API_LOCAL
    } else {
      // default goes back to UK
      this.axiosIns.defaults.baseURL = process.env.VUE_APP_API_UK
    }
  }
}

const countryService = new CountryService(axios)
export default countryService
