export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/CalendarEvents.vue'),
    meta: {
      resource: 'CalendarEvents',
      action: 'Read',
      pageTitle: 'Calendar Events',
      breadcrumb: [
        {
          text: 'Manage Calendar Events',
          active: true,
        }
      ]
    }
  }
]
