export default [
  {
    path: '/admin/devices',
    name: 'admin-devices',
    component: () => import('@/views/adminDevices/AdminDevices'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'All Devices',
      breadcrumb: [
        {
          text: 'Showing all tablets and phones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/devices/:uid',
    name: 'admin-device-view',
    props: true,
    component: () => import('@/views/adminDevices/viewDevice/ViewDevice.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device',
      breadcrumb: [
        {
          text: 'All Devices',
          to: { name: 'admin-devices' },
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
]
