import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

import enterprises from '@/router/routes/enterprises'
import users from '@/router/routes/users';
import groups from '@/router/routes/groups';
import enrollment from '@/router/routes/enrollment';
import networks from '@/router/routes/networks';
import devices from '@/router/routes/devices';
import issues from '@/router/routes/issues'
import purpleAccounts from '@/router/routes/purple-accounts';
import machines from '@/router/routes/machines';
import dashboard from './routes/dashboard';
import auth from './routes/auth';
import twoFactor from './routes/two-factor';
import owners from './routes/owners';
import possessions from './routes/possessions';
import locations from './routes/locations';
import roles from './routes/roles';
import kiosk from './routes/kiosk';
import eapiPolicies from './routes/eapi-policies'
import bills from './routes/bills'
import tracker from './routes/tracker'
import purpleScribe from '@/router/routes/purple-scribe';
import pdfForms from '@/router/routes/pdf-forms';
import purplePlay from '@/router/routes/purple-play';
import PurpleDocs from "@/router/routes/purple-docs";
import announcements from "./routes/announcements";
import calendar from "@/router/routes/calendar";
import adminDevices from "@/router/routes/admin-devices"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...auth,
    ...twoFactor,
    ...users,
    ...groups,
    ...enrollment,
    ...networks,
    ...devices,
    ...machines,
    ...owners,
    ...possessions,
    ...locations,
    ...roles,
    ...issues,
    ...purpleAccounts,
    ...kiosk,
    ...eapiPolicies,
    ...bills,
    ...tracker,
    ...purpleScribe,
    ...pdfForms,
    ...purplePlay,
    ...PurpleDocs,
    ...announcements,
    ...calendar,
    ...adminDevices,
    {
      path: '/keys',
      name: 'keys',
      component: () => import('@/views/keys/Keys.vue'),
      meta: {
        resource: 'Keys',
        action: 'Read',
        pageTitle: 'Keys',
        breadcrumb: [
          {
            text: 'Keys',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/activities/Activities.vue'),
      meta: {
        resource: 'Activity',
        action: 'Read',
        pageTitle: 'Activities',
        breadcrumb: [
          {
            text: 'Activities',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        resource: 'Default',
        action: 'Read',
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    ...enterprises,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'Read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
      resource: 'Auth',
      action: 'Read',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'auth-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
