export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/Roles.vue'),
    meta: {
      resource: 'Role',
      action: 'Read',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/role/:id',
    name: 'role-view',
    props: true,
    component: () => import('@/views/roles/viewRoles/ViewRoles.vue'),
    meta: {
      resource: 'Role',
      action: 'Update',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'All Roles',
          to: { name: 'roles' },
        },
        {
          text: 'Role',
          active: true,
        },
      ],
    },
  },
]
