export default [
  {
    path: '/announcements',
    name: 'announcements',
    component: () => import('@/views/announcements/KioskAnnouncements.vue'),
    meta: {
      resource: 'KioskAnnouncements',
      action: 'Read',
      pageTitle: 'Kiosk Announcements',
      breadcrumb: [
        {
          text: 'Important Kiosk Announcements',
          active: true,
        }
      ]
    }
  }
]
