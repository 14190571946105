export default [
  {
    path: '/owners',
    name: 'owners',
    component: () => import('@/views/owners/Owners.vue'),
    meta: {
      resource: 'Owner',
      action: 'Read',
      pageTitle: 'Device Owners',
      breadcrumb: [
        {
          text: 'All Owners',
          active: true,
          to: { name: 'owners' },
        },
      ],
    },
  },
  {
    path: '/owners/owner/:uid',
    name: 'owner-view',
    props: true,
    component: () => import('@/views/owners/ViewOwner.vue'),
    meta: {
      resource: 'Owner',
      action: 'Read',
      pageTitle: 'Owners',
      breadcrumb: [
        {
          text: 'All Owners',
          to: { name: 'owners' },
        },
        {
          text: 'Owner View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/owners/activities',
    name: 'owner-activities',
    component: () => import('@/views/owners/Activities.vue'),
    meta: {
      resource: 'Owner',
      action: 'Read',
      pageTitle: 'Owner Activities',
    },
  },
]
