export default [
  {
    path: '/machines',
    name: 'machines',
    component: () => import('@/views/machines/Machines.vue'),
    meta: {
      resource: 'Machine',
      action: 'Read',
      pageTitle: 'Machines',
      breadcrumb: [
        {
          text: 'Showing MadepurpleOS Machines',
          active: true,
        },
      ],
    },
  },
  {
    path: '/machines/:id',
    name: 'machine-view',
    props: true,
    component: () => import('@/views/machines/view/Machine.vue'),
    meta: {
      resource: 'Machine',
      action: 'Read',
      pageTitle: 'Machine',
      breadcrumb: [
        {
          text: 'All Machines',
          to: { name: 'machines' },
        },
        {
          text: 'Machine',
          active: true,
        },
      ],
    },
  },
]
