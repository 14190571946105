export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      resource: 'User',
      action: 'Read',
      pageTitle: 'System Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/user/:uid',
    name: 'user-view',
    props: true,
    component: () => import('@/views/users/ViewUser.vue'),
    meta: {
      resource: 'User',
      action: 'Read',
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'All Users',
          to: { name: 'users' },
        },
        {
          text: 'User',
          active: true,
        },
      ],
    },
  },
]
