export default [
  {
    path: '/networks',
    name: 'networks',
    component: () => import('@/views/networks/Networks.vue'),
    meta: {
      resource: 'Network',
      action: 'Read',
      pageTitle: 'Network List',
      breadcrumb: [
        {
          text: 'Networks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-view/:uid',
    name: 'network-view',
    props: true,
    component: () => import('@/views/networks/ViewNetwork.vue'),
    meta: {
      resource: 'Network',
      action: 'Read',
      pageTitle: 'Network',
      breadcrumb: [
        {
          text: 'Networks',
          to: { name: 'networks' },
        },
        {
          text: 'Network',
          active: true,
        },
      ],
    },
  },
  {
    path: '/network-edit/:uid',
    name: 'network-edit',
    props: true,
    component: () => import('@/views/networks/editNetwork/EditNetwork.vue'),
    meta: {
      resource: 'Network',
      action: 'Update',
      pageTitle: 'Network',
      breadcrumb: [
        {
          text: 'Networks',
          to: { name: 'networks' },
        },
        {
          text: 'Edit Network',
          active: true,
        },
      ],
    },
  },
]
