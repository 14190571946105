export default [
  {
    path: '/pdf-forms',
    name: 'pdf-forms',
    component: () => import('@/views/pdfForms/PDFForms.vue'),
    meta: {
      resource: 'PDFForm',
      action: 'Read',
      pageTitle: 'PDF Forms',
      breadcrumb: [
        {
          text: 'PDF Forms',
          active: true,
        },
      ],
    }
  }
]
