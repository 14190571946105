export default [
  {
    path: '/admin/bills',
    name: 'view-bills',
    component: () => import('@/views/billing/ViewBills.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'View Bills',
      breadcrumb: [
        {
          text: 'All Bills',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/bills/unbilled',
    name: 'unbilled-enterprises',
    component: () => import('@/views/billing/CreateBills.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Unbilled Enterprises',
      breadcrumb: [
        {
          text: 'Unbilled Enterprises',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/bills/:id',
    name: 'bill-details',
    props: true,
    component: () => import('@/views/billing/BillDetails.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Bill Details',
      breadcrumb: [
        {
          text: 'All Bills',
          to: { name: 'view-bills' },
        },
        {
          text: 'View Bill',
          active: true,
        },
      ],
    },
  },
]
