export default [
  {
    path: '/admin/purple-accounts',
    name: 'purple-accounts',
    component: () => import('@/views/purpleAccounts/PurpleAccounts.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Purple Accounts',
      breadcrumb: [
        {
          text: 'Purple Accounts',
          active: true,
        },
      ],
    },
  },
]
